import ListAPI from "@/apps/core/api/list";
import { PotonganGaji, PotonganGajiAPI } from "./potonganGaji";
import ViewListModel from "@/apps/core/models/viewListModel";

class PotonganGajiListAPI extends ListAPI {
  constructor() {
    super(PotonganGajiAPI.modelPath);
  }
}

class PotonganGajiListVM extends ViewListModel {
  constructor() {
    super(new PotonganGajiListAPI(), undefined, false);
  }

  getInstance(json: Record<string, any>): PotonganGaji {
    const potongan = new PotonganGaji();
    potongan.loadData(json);
    return potongan;
  }
}

export { PotonganGajiListAPI };
export default PotonganGajiListVM;
