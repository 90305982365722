
































































































import axios from "axios";
import { defineComponent, reactive, ref, Ref, watch } from "@vue/composition-api";
import APP_CONFIG from "@/apps/core/modules/config";
import { formatRupiah } from "@/apps/core/modules/utils/text";
import { toUserDateTimeFormat } from "@/apps/core/modules/utils/datetime";
// import useViewModel from "@/apps/core/modules/useViewModel";
import NeracaVM, { Neraca } from "@/apps/laporan/models/neraca";

export default defineComponent({
  name: "NeracaDetail",
  components: {
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue")
  },
  setup() {
    /*
      TODO:
      - tombol edit bisa muncul kalau masih tahun yg sama dan ada batasnya
      - katakanlah hanya bisa tambah dan edit hingga akhir bulan feb
      - jika context-nya bisa tambah dan edit, baiknya gunakan context menu
    */

    const year = ref(0);
    const yearOptions: Ref<Array<number>> = ref([]);
    const neracaVM = new NeracaVM();
    const neraca = reactive(new Neraca());

    const actionData = reactive(
      {type: null, routeName: null} as Record<string, any>
    );

    // ambil daftar tahun neraca sebagai pilihan dropdown
    const listUrl = `${APP_CONFIG.baseAPIURL}/laporan/daftar-tahun-neraca/`;
    axios.get(listUrl).then((response) => {
      yearOptions.value = response.data.data;
      if (yearOptions.value.length > 0) {
        year.value = yearOptions.value[0];
      }
      const lastYear = (new Date()).getUTCFullYear() - 1;
      if (!yearOptions.value.includes(lastYear)) {
        Object.assign(actionData, {type: "add", routeName: "laporan-neraca-add"});
      }
    });

    // update view ketika year berubah
    watch(year, async (newVal) => {
      await neracaVM
        .fetch(newVal.toString())
        .then(() => Object.assign(neraca, neracaVM.instance as Neraca));
    });

    return {
      // Data
      actionData,
      neraca,
      year,
      yearOptions,

      // Method
      formatRupiah,
      toUserDateTimeFormat,
    };
  },
});
