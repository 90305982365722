import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";
import { Nasabah } from "@/apps/nasabah/models/nasabah";
import { PotonganBulanan } from "./potonganBulanan";


class PotonganGaji extends PlainModel {
  potonganBulanan: PotonganBulanan | null = null;
  nasabah: Nasabah | null = null;
  sp: number | null = null;
  sw: number | null = null;
  ss: number | null = null;
  buku: number | null = null;
  totalSimpanan: number | null = null;
  totalHutang: number | null = null;
  totalPotongan: number | null = null;
  ssEditedId: string | null = null;
  hutangEditedId: string | null = null;
}

class PotonganGajiAPI extends API {
  static modelPath = "/laporan/potongan/";

  constructor() {
    super(PotonganGajiAPI.modelPath);
  }
}

export default class PotonganGajiVM extends ViewModel {
  constructor() {
    super(new PotonganGajiAPI(), new PotonganGaji());
  }
}


export { PotonganGaji, PotonganGajiAPI };