import FormModel from "@/apps/core/models/formModel";
import { PotonganBulanan, PotonganBulananAPI } from "./potonganBulanan";

export default class PotonganBulananFM extends FormModel {
  constructor() {
    super(
      new PotonganBulananAPI(),
      new PotonganBulanan(),
      [],
      []
    );
  }
}
