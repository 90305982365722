import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";
import { Pengelola } from "@/apps/pengelola/models/pengelola";


class PotonganBulanan extends PlainModel {
  tanggal: string | null = null;
  approved: Pengelola | null = null;
}

class PotonganBulananAPI extends API {
  static modelPath = "/laporan/potongan-bulanan/";

  constructor() {
    super(PotonganBulananAPI.modelPath);
  }
}

export default class PotonganBulananVM extends ViewModel {
  constructor() {
    super(new PotonganBulananAPI(), new PotonganBulanan());
  }
}


export { PotonganBulanan, PotonganBulananAPI };