import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";

class KomponenNeraca extends PlainModel {
  urutan: number | null = null;
  jenis: string | null = null;
  nama: string | null = null;
  nilai: number | null = null;
  catatan: string | null = null;
}

class Neraca extends PlainModel {
  tahun: number | null = null;
  totalHarta: number | null = null;
  totalUm: number | null = null;
  komponenSet: Array<KomponenNeraca> | null = null;

  loadData(json: Record<string, any>): void {
    if (json.komponenneraca_set) {
      const komponenSet = json.komponenneraca_set as Array<Record<string, any>>;
      json.komponenSet = [];
      komponenSet.forEach((komponenJson) => {
        const komponen = new KomponenNeraca();
        delete komponenJson.neraca_id;
        komponen.loadData(komponenJson);
        json.komponenSet.push(komponen);
      });
      delete json.komponenneraca_set;
    }
    super.loadData(json);
  }

  getHartaSet(): Array<KomponenNeraca> {
    if (!this.komponenSet) return [];
    return this.komponenSet.filter((komp) => komp.jenis === "Harta");
  }

  getModalSet(): Array<KomponenNeraca> {
    if (!this.komponenSet) return [];
    return this.komponenSet.filter((komp) => komp.jenis === "Modal");
  }

  getUtangSet(): Array<KomponenNeraca> {
    if (!this.komponenSet) return [];
    return this.komponenSet.filter((komp) => komp.jenis === "Utang");
  }
}

class NeracaAPI extends API {
  static modelPath = "/laporan/neraca/";

  constructor() {
    super(NeracaAPI.modelPath);
  }
}

export default class NeracaVM extends ViewModel {
  constructor() {
    super(new NeracaAPI(), new Neraca());
  }
}

export { KomponenNeraca, Neraca, NeracaAPI };
