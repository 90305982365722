import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";

class KomponenLabaRugi extends PlainModel {
  urutan: number | null = null;
  jenis: string | null = null;
  nama: string | null = null;
  nilai: number | null = null;
  catatan: string | null = null;
}

class LabaRugi extends PlainModel {
  tahun: number | null = null;
  totalPendapatan: number | null = null;
  totalBeban: number | null = null;
  laba: number | null = null;
  komponenSet: Array<KomponenLabaRugi> | null = null;

  loadData(json: Record<string, any>): void {
    if (json.komponenlabarugi_set) {
      const komponenSet = json.komponenlabarugi_set as Array<Record<string, any>>;
      json.komponenSet = [];
      komponenSet.forEach((komponenJson) => {
        const komponen = new KomponenLabaRugi();
        delete komponenJson.laporan_id;
        komponen.loadData(komponenJson);
        json.komponenSet.push(komponen);
      });
      delete json.komponenlabarugi_set;
    }
    super.loadData(json);
  }

  getPendapatanSet(): Array<KomponenLabaRugi> {
    if (!this.komponenSet) return [];
    return this.komponenSet.filter((komp) => komp.jenis === "Pendapatan");
  }

  getBebanSet(): Array<KomponenLabaRugi> {
    if (!this.komponenSet) return [];
    return this.komponenSet.filter((komp) => komp.jenis === "Beban");
  }
}

class LabaRugiAPI extends API {
  static modelPath = "/laporan/laba-rugi/";

  constructor() {
    super(LabaRugiAPI.modelPath);
  }
}

export default class LabaRugiVM extends ViewModel {
  constructor() {
    super(new LabaRugiAPI(), new LabaRugi());
  }
}

export { KomponenLabaRugi, LabaRugi, LabaRugiAPI };
