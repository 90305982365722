import APP_CONFIG from "@/apps/core/modules/config";
import Excel from "exceljs";
import { PotonganGaji } from "@/apps/laporan/models/potonganGaji";
import { ToastProgrammatic as Toast } from "buefy";
import axios from "axios";

const COLS = [
  "NO",
  "PAYROLL",
  "PAYROLL NAME",
  "UNIT KERJA",
  "SIMPANAN KOPERASI",
];

const SIMP_COLS = ["SP", "SW", "SS", "JUMLAH SIMPANAN"];

class PotonganGajiExcel {
  apiUrl: string;
  onSaved: (() => void) | null;
  onFinished: (() => void) | null;
  month: string;
  year: string;
  filename: string | null = null;
  constructor(
    apiUrl: string,
    month: string,
    year: string,
    onSaved: (() => void) | null,
    onFinished: (() => void) | null
  ) {
    this.apiUrl = apiUrl;
    this.onSaved = onSaved;
    this.onFinished = onFinished;
    this.month = month;
    this.year = year;
  }

  async writeWorkbook(): Promise<void> {
    axios
      .get(this.apiUrl, { params: { "fields_type": "options", "tahun": this.year, "bulan": this.month } })
      .then((response: Record<string, any>) => {
        this._writeToExcel(response.data.data);
      })
      .catch(() => {
        // this.reset();
        Toast.open("File gagal di download.");
      });
  }

  getCurrentDatetime(): string {
    const currentdt = new Date();
    const mm = currentdt.getMonth() + 1; // getMonth() is zero-based
    const dd = currentdt.getDate();
    const hh = currentdt.getHours();
    const mi = currentdt.getMinutes();
    const se = currentdt.getSeconds();

    return [
      currentdt.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
      (hh > 9 ? "" : "0") + hh,
      (mi > 9 ? "" : "0") + mi,
      (se > 9 ? "" : "0") + se,
    ].join("");
  }

  async _writeToExcel(dataList: Array<Record<string, any>>): Promise<void> {
    let num = 0;
    const jsonArr: Array<any> = [];
    for (const data of dataList) {
      num++;
      const potongan = new PotonganGaji();
      potongan.loadData(data);
      const row = [
        num,
        potongan.nasabah?.noAnggota,
        potongan.nasabah?.nama,
        potongan.nasabah?.unitKerja?.nama ?? "",
        potongan.sp,
        potongan.sw,
        potongan.ss,
        potongan.totalSimpanan,
        potongan.totalHutang,
        potongan.totalPotongan,
      ];
      jsonArr.push(row);
    }
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("Potongan Gaji");
    // worksheet.getRow(6).values = SIMP_COLS;

    const cellA2 = worksheet.getCell("A2");
    const cellA3 = worksheet.getCell("A3");
    worksheet.getRow(1).hidden = true;

    cellA2.value = "KOPERASI YAKOBUS";
    cellA2.style = { font: { size: 20, bold: true } };
    worksheet.getRow(2).height = 27;
    const subJudul = `Potongan Gaji ${APP_CONFIG.months[parseInt(this.month)]} ${this.year}`;
    cellA3.value = subJudul;
    cellA3.style = { font: { size: 16 } };
    worksheet.getRow(3).height = 18;

    worksheet.getRow(5).values = COLS;
    worksheet.getColumn("A").width = 5; // no
    worksheet.getColumn("B").width = 10; // no anggota
    worksheet.getColumn("C").width = 25; // nama
    worksheet.getColumn("D").width = 15; // unit
    worksheet.getColumn("E").width = 15; // sp
    worksheet.getColumn("F").width = 20; // sw
    worksheet.getColumn("G").width = 25; // ss
    worksheet.getColumn("H").width = 25; // jumlah
    worksheet.getColumn("I").width = 25; // hutang
    worksheet.getColumn("J").width = 25; // potongan

    for (const rNum of Array(2).keys()) {
      const num = rNum + 5;
      worksheet.getRow(num).alignment = {
        wrapText: true,
        vertical: "middle",
        horizontal: "center",
      };
      for (const cidx of Array(10).keys()) {
        worksheet.getCell(num, cidx + 1).fill = {
          type: "pattern",
          pattern: "lightGray"
        };
        worksheet.getCell(num, cidx + 1).font = { bold: true }
        worksheet.getCell(num, cidx + 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }

    worksheet.mergeCells("E5:H5");
    worksheet.getCell("I5").value = "HUTANG KOPERASI";
    worksheet.getCell("J5").value = "TOTAL POTONGAN KOPERASI";

    ["E", "F", "G", "H"].forEach((col, i) => {
      worksheet.getCell(`${col}6`).value = SIMP_COLS[i];
    });
    ["A", "B", "C", "D", "I", "J"].map((col) => {
      worksheet.mergeCells(`${col}5:${col}6`);
    });

    const startRowNum = 7;
    // const headerRowCount = 2;
    // const headerColCount = 6;

    for (const rowNum of Array(jsonArr.length).keys()) {
      for (const cidx of Array(10).keys()) {
        const dataCell = worksheet.getCell(
          rowNum + startRowNum, cidx + 1
        );
        const val = jsonArr[rowNum][cidx];
        dataCell.value = val;
        if (cidx > 4) dataCell.numFmt = "#,##0.00";
        worksheet.getCell(rowNum + startRowNum, cidx + 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(
      new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    this.filename = `Koperasi_Potongan_Gaji_${APP_CONFIG.months[parseInt(this.month)]}_${
      this.year
    }_${this.getCurrentDatetime()}.xlsx`
      .replace("-", "")
      .split(" ")
      .join("_");
    link.setAttribute("download", this.filename); //or any other extension
    // link.download = this.filename;
    document.body.appendChild(link);
    link.click();
    // window.URL.revokeObjectURL(url);
  }
}

export default PotonganGajiExcel;
