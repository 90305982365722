








































































































import { defineComponent, ref } from "@vue/composition-api";
import NeracaFM from "../models/neracaForm";
import router from "@/router";
// import { SaveContext } from "@/apps/core/models/formModel";
import useFormModel from "@/apps/core/modules/useFormModel";
import { KomponenNeraca, Neraca } from "../models/neraca";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "NeracaForm",
  props: ["headerText"],
  components: {
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const requiredFields = ["tahun"];
    const nonRequiredFields = ["totalHarta", "totalUm", "komponenSet"];
    const komponenRequiredField = ["jenis", "nama", "nilai"];
    // const komponenNonRequiredField = ["catatan"];
    const neracaId = router.currentRoute.params.id;
    const form = new NeracaFM(requiredFields, nonRequiredFields);
    const composition = useFormModel(form, neracaId);
    const neraca = composition.instance as Neraca;
    neraca.tahun = new Date().getFullYear() - 1;
    const errorMapArr: Array<Record<string, string | null>> = [];
    const errorArr: Array<string | null> = [];

    const allIsValid = ref(false);

    const updateAllIsValid = () => {
      const cleanArr = errorArr.filter((el) => el !== null);
      if (cleanArr.length == 0) {
        allIsValid.value = false;
      } else {
        allIsValid.value = cleanArr.every((el) => el === "");
      }
    };

    const updateErrorArr = (idx: number, field: string) => {
      const errorMap = errorMapArr[idx];
      const errVals = Object.values(errorMap);
      const valid = errVals.every((el) => el === "");
      if (valid) {
        errorArr[idx] = "";
      } else {
        if (errorMap[field] !== "") {
          errorArr[idx] = errorMap[field];
        } else {
          for (const err of errVals) {
            if (err !== "") {
              errorArr[idx] = err;
            }
          }
        }
      }
    };

    const getEmptyKomponenErrorMap = (): Record<string, string | null> => {
      errorArr.push(null);
      const errorMap = {} as Record<string, string | null>;
      for (const fld of komponenRequiredField) {
        errorMap[fld] = null;
      }
      return errorMap;
    };

    const getEmptyKomponen = (): KomponenNeraca => {
      errorMapArr.push(getEmptyKomponenErrorMap());
      const komponen = new KomponenNeraca();
      komponen.catatan = "";
      return komponen;
    };

    neraca.komponenSet = [getEmptyKomponen()];

    const addNewKomponen = (komponenSet: Array<KomponenNeraca>) => {
      // console.log("isLastRow, create new komponen");
      komponenSet.push(getEmptyKomponen());
    };

    const validateKomponen = (idx: number, field: string) => {
      // console.log(idx, field);
      if (neraca?.komponenSet) {
        const kompLen = neraca.komponenSet.length;
        // console.log(kompLen);
        // validate all fields
        for (const fld of komponenRequiredField) {
          const komponenNeraca: Record<string, any> = neraca.komponenSet[idx];
          errorMapArr[idx][fld] = komponenNeraca[fld]
            ? ""
            : `${fld} perlu diisi.`;
        }

        updateErrorArr(idx, field);
        updateAllIsValid();
        if (idx === kompLen - 1) {
          addNewKomponen(neraca.komponenSet);
        }
      }
    };

    const removeKomponen = (idx: number) => {
      if (neraca?.komponenSet && neraca.komponenSet.length > 1) {
        neraca.komponenSet.splice(idx, 1);
        errorMapArr.splice(idx, 1);
        errorArr.splice(idx, 1);
        updateAllIsValid();
      }
    };

    return {
      // Data
      allIsValid,
      errorArr,
      errorMapArr,
      neracaId,

      // Composition
      ...composition,

      // Method
      removeKomponen,
      validateKomponen,
    };
  },
});
