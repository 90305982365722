import FormModel from "@/apps/core/models/formModel";
import { KomponenLabaRugi, LabaRugi, LabaRugiAPI } from "./labaRugi";

export default class LabaRugiFM extends FormModel {
  constructor(requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(new LabaRugiAPI(), new LabaRugi(), requiredFields, nonRequiredFields);
  }

  getPayload(): Record<string, any> {
    const payload = super.getPayload();
    // console.log(payload);
    for (const [idx, komponen] of payload.komponen_set.entries()) {
      delete komponen.id;
      delete komponen.extraData;
      komponen.urutan = idx + 1;
    }
    const komponenSet: Array<KomponenLabaRugi> = payload.komponen_set;
    payload.komponenlabarugi_set = komponenSet.filter(
      (komp) => komp.nama !== null
    );
    delete payload.komponen_set;
    // console.log(payload);
    return payload;
  }
}
