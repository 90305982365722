




































































































import { defineComponent, ref } from "@vue/composition-api";
import LabaRugiFM from "../models/labaRugiForm";
import router from "@/router";
import useFormModel from "@/apps/core/modules/useFormModel";
import { KomponenLabaRugi, LabaRugi } from "../models/labaRugi";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "LabaRugiForm",
  props: ["headerText"],
  components: {
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const requiredFields = ["tahun"];
    const nonRequiredFields = ["totalPendapatan", "totalBeban"];
    const komponenRequiredField = ["jenis", "nama", "nilai"];
    // const komponenNonRequiredField = ["catatan"];
    const labaRugiId = router.currentRoute.params.id;
    const form = new LabaRugiFM(requiredFields, nonRequiredFields);
    const composition = useFormModel(form, labaRugiId);
    const labaRugi = composition.instance as LabaRugi;
    labaRugi.tahun = new Date().getFullYear() - 1;
    const errorMapArr: Array<Record<string, string | null>> = [];
    const errorArr: Array<string | null> = [];

    const allIsValid = ref(false);

    const updateAllIsValid = () => {
      const cleanArr = errorArr.filter((el) => el !== null);
      if (cleanArr.length == 0) {
        allIsValid.value = false;
      } else {
        allIsValid.value = cleanArr.every((el) => el === "");
      }
    };

    const updateErrorArr = (idx: number, field: string) => {
      const errorMap = errorMapArr[idx];
      const errVals = Object.values(errorMap);
      const valid = errVals.every((el) => el === "");
      if (valid) {
        errorArr[idx] = "";
      } else {
        if (errorMap[field] !== "") {
          errorArr[idx] = errorMap[field];
        } else {
          for (const err of errVals) {
            if (err !== "") {
              errorArr[idx] = err;
            }
          }
        }
      }
    };

    const getEmptyKomponenErrorMap = (): Record<string, string | null> => {
      errorArr.push(null);
      const errorMap = {} as Record<string, string | null>;
      for (const fld of komponenRequiredField) {
        errorMap[fld] = null;
      }
      return errorMap;
    };

    const getEmptyKomponen = (): KomponenLabaRugi => {
      errorMapArr.push(getEmptyKomponenErrorMap());
      const komponen = new KomponenLabaRugi();
      komponen.catatan = "";
      return komponen;
    };

    labaRugi.komponenSet = [getEmptyKomponen()];

    const addNewKomponen = (komponenSet: Array<KomponenLabaRugi>) => {
      // console.log("isLastRow, create new komponen");
      komponenSet.push(getEmptyKomponen());
    };

    const validateKomponen = (idx: number, field: string) => {
      // console.log(idx, field);
      if (labaRugi?.komponenSet) {
        const kompLen = labaRugi.komponenSet.length;
        // console.log(kompLen);
        // validate all fields
        for (const fld of komponenRequiredField) {
          const komponenLabaRugi: Record<string, any> =
            labaRugi.komponenSet[idx];
          errorMapArr[idx][fld] = komponenLabaRugi[fld]
            ? ""
            : `${fld} perlu diisi.`;
        }

        updateErrorArr(idx, field);
        updateAllIsValid();
        if (idx === kompLen - 1) {
          addNewKomponen(labaRugi.komponenSet);
        }
      }
    };

    const removeKomponen = (idx: number) => {
      if (labaRugi?.komponenSet && labaRugi.komponenSet.length > 1) {
        labaRugi.komponenSet.splice(idx, 1);
        errorMapArr.splice(idx, 1);
        errorArr.splice(idx, 1);
        updateAllIsValid();
      }
    };

    return {
      // Data
      allIsValid,
      errorArr,
      errorMapArr,
      labaRugiId,

      // Composition
      ...composition,

      // Method
      removeKomponen,
      validateKomponen,
    };
  },
});
