












































































































































































import { computed, defineComponent, reactive } from "@vue/composition-api";
import useListViewModel from "@/apps/core/modules/useListViewModel";
import { formatRupiah } from "@/apps/core/modules/utils/text";
import PotonganGajiListVM from "../models/potonganGajiList";
import PotonganBulananFM from "../models/potonganBulananForm";
import { SaveContext } from "@/apps/core/models/formModel";
import {
  DialogProgrammatic as Dialog,
  ToastProgrammatic as Toast,
} from "buefy";
import axios from "axios";
import APP_CONFIG from "@/apps/core/modules/config";
import { PotonganGaji } from "../models/potonganGaji";
import PotonganGajiExcel from "../modules/potonganGajiExcel";

export default defineComponent({
  name: "PemotonganList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
    MonthSelect: () => import("@/apps/core/components/MonthSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const listVM = new PotonganGajiListVM();
    listVM.addFilterMap({ tahun: "", bulan: "" });
    const composition = useListViewModel(listVM);

    const form = new PotonganBulananFM();
    let sukarelaRecord = reactive({} as Record<string, string>);

    const addSukarelaToRecord = (potongan: PotonganGaji) => {
      const id = potongan.id ?? "";
      const nilaiSs = {} as Record<string, any>;
      nilaiSs[id] = { ss: potongan.ss };
      Object.assign(sukarelaRecord, nilaiSs);
    };

    const generatePotongan = async () => {
      await form.save(SaveContext.Create, false);
      composition.onPageChange(1);
    };

    const updateSS = async (callback?: () => void) => {
      try {
        const updateUrl = `${APP_CONFIG.baseAPIURL}/laporan/potongan/batch/`;
        const response = await axios.patch(updateUrl, {
          "data_set": sukarelaRecord,
        });
        for (const [id, data] of Object.entries(
          response.data as Record<string, any>
        )) {
          const potongan = listVM.list.filter((item) => item.id === id);
          if (potongan) {
            potongan[0].totalSimpanan = data.totalSimpanan;
            potongan[0].totalPotongan = data.totalPotongan;
            potongan[0].ssEditedId = data.ssEditedId;
          }
        }
        Toast.open("Berhasil menyimpan data.");
        if (callback) callback();
        sukarelaRecord = {};
      } catch {
        Toast.open("Gagal menyimpan data.");
      }
    };

    const downloadLaporan = () => {
      const laporanUrl = `${APP_CONFIG.baseAPIURL}/laporan/potongan/`;
      const _month = composition.filterMap.bulan;
      const _year = composition.filterMap.tahun;
      const potonganGajiExcel = new PotonganGajiExcel(
        laporanUrl,
        _month,
        _year,
        null,
        () => (composition.listVMRef.isLoading = false)
      );
      potonganGajiExcel.writeWorkbook();
    };

    const confirmPotongan = () => {
      Dialog.confirm({
        message: "Anda yakin akan membuat laporan potongan di bulan ini?",
        confirmText: "Ya",
        cancelText: "Tidak",
        onConfirm: () => generatePotongan(),
      });
    };

    const confirmUpdate = () => {
      Dialog.confirm({
        message: "Anda yakin akan melakukan perubahan ini?",
        confirmText: "Ya",
        cancelText: "Tidak",
        onConfirm: () => updateSS(),
      });
    };

    const confirmExport = () => {
      Dialog.confirm({
        message: "Download data laporan?",
        confirmText: "Ya",
        cancelText: "Tidak",
        onConfirm: () => downloadLaporan(),
      });
    };

    const today = new Date();
    const year = today.getFullYear().toString();
    const month = today.getMonth().toString();
    composition.filterMap.tahun = year;
    composition.filterMap.bulan = month;
    composition.listVMRef.filtered = true;
    const monthLabel = computed(
      () => APP_CONFIG.months[parseInt(composition.filterMap.bulan)]
    );
    const canGenerate = computed(() => {
      // console.log(today.getDate());
      // console.log(APP_CONFIG.tglCutoff);
      // console.log(composition.listVMRef.list.length);
      // console.log(composition.filterMap.tahun);
      // console.log(composition.filterMap.bulan);
      // console.log(year);
      // console.log(month);
      // console.log(listVM.filtered);
      // console.log(composition.filterMap.unitKerja);
      // console.log(composition.filterMap.q);
      return (
        today.getDate() < APP_CONFIG.tglCutoff &&
        composition.listVMRef.list.length == 0 &&
        year === composition.filterMap.tahun &&
        month === composition.filterMap.bulan &&
        listVM.filtered &&
        !composition.filterMap.unitKerja &&
        composition.filterMap.q === ""
      );
    });

    const changePage = (value: string) => {
      const redirect =
        value === "next" ? composition.nextPage : composition.prevPage;
      if (Object.keys(sukarelaRecord).length > 0) {
        Dialog.confirm({
          message: "Simpan perubahan",
          confirmText: "Ya",
          cancelText: "Tidak",
          onConfirm: () => updateSS(redirect),
          onCancel: () => {
            sukarelaRecord = {};
            redirect();
          },
        });
      } else {
        redirect();
      }
    };

    const canUpdate = computed(() => {
      return (
        today.getDate() < APP_CONFIG.tglCutoff &&
        year === composition.filterMap.tahun &&
        month === composition.filterMap.bulan &&
        listVM.list.length > 0 &&
        listVM.filtered
      );
    });
    // const canUpdate = true;
    return {
      // Computed
      canGenerate,
      // filtered,
      monthLabel,

      // Method
      addSukarelaToRecord,
      changePage,
      formatRupiah,
      confirmExport,
      confirmUpdate,
      confirmPotongan,

      // Composition
      canUpdate,
      ...composition,
    };
  },
});
